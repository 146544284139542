<template>
    <div class="login">
        <div class="login_title">方案生成系统</div>
        <div class="login_content">
            <el-form 
                :model="registerForm"
                :rules="registerRules" 
                ref="registerForm">
                <el-form-item label="账号" prop="username">
                    <el-input v-model.trim="registerForm.username" clearable></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input type="password" v-model.trim="registerForm.password" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="login_content_btn" @click="registerHandler">登陆</div>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import $http from '@/service/index'
export default {
    name: 'login',
    data() {
        return {
            registerForm: { // 登录参数信息
                username: '',
                password: '',
                project: '1', // 该字段标记，什么系统登录，后端动态返回路由信息条件
            },
            registerRules: {
                username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        /**
         * 登录
         */
        async registerHandler() {
            await this.$refs.registerForm.validate();
            const params = {
                data: this.registerForm,
                config: {
                    showLoading: true,
                    text: '登录中...'
                }
            }
            const registerInfo = await $http.authLogin(params);
            if (registerInfo.code == '000000') {
                const userInfo = {...registerInfo.result, token: registerInfo.token};
                this.LocalStorage.set('userInfo', userInfo);
                this.$router.replace({
                    path: '/programme'
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>

.login {
    width: 100%;
    height: 100%;
    background: url('../../static/img/login/main-nav-bg3.png') no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .login_title {
        font-size: 36px;
        letter-spacing: -3px;
        color: #F1F1F1;
        margin: 0px;
        font-weight: bold;
        margin-bottom: 19px;
        text-align: center;
    }
    .login_content {
        width: 500px;
        height: 320px;
        background: #FFFFFF;
        box-shadow: 0px 6px 18px 0px rgba(55, 77, 135, 0.25);
        border-radius: 4px;
        padding: 35px 100px;
        box-sizing: border-box;
        /deep/ .el-form {
            .el-form-item {
                .el-form-item__label {
                    font-size: 20px;
                }
            }
        }
        .login_content_btn {
            user-select: none;
            height: 40px;
            line-height: 40px;
            width: 100%;
            text-align: center;
            font-size: 18px;
            border-radius: 10px;
            background: #409EFF;
            box-shadow: 0px 5px 8px 0px rgba(49, 100, 49, 0.21);
            border-radius: 4px;
            color: #FFFFFF;
            &:hover {
                cursor: pointer;
                background: #2e7aec;
            }
        }
    }
}
</style>